//
// Copyright 2023 DXOS.org
//

export default [
  {
    'en-US': {
      testbench: {},
    },
  },
];
