//
// Copyright 2024 DXOS.org
//

//
// `defaultSize`, the final fallbacks
//

export const defaultSizeRow = 30;
export const defaultSizeCol = 180;
